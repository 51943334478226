export const lang = {
  语言: "English",
  登录: "登录",
  微信登录: "微信登录",
  账户登录: "账户登录",
  请使用微信扫描二维码登录: "请使用微信扫描二维码登录",
  公司版权所有北京路晨伟业仪器设备有限公司: "公司版权所有：北京路晨伟业仪器设备有限公司",
  京ICP备18062262号1: "京ICP备18062262号-1",
  设备数据: "设备数据",
  首页: "首页",
  手动单通道: "手动单通道",
  自动单通道: "自动单通道",
  手动双通道: "手动双通道",
  自动双通道: "自动双通道",
  单通道带剪切力: "单通道带剪切力",
  静力触探: "静力触探",
  修改密码: "修改密码",
  退出: "退出",
  旧密码: "旧密码",
  新密码: "新密码",
  确认新密码: "确认新密码",
  保存: "保存",
  取消: "取消",
  密码至少为6位: "密码至少为6位",
  密码错误: "密码错误",
  微信用户: "微信用户",
  确定要退出吗: "确定要退出吗？",
  新增绑定设备: "新增绑定设备",
  设备序列号: "设备序列号",
  经销商电话: "经销商电话",
  备注: "备注",
  绑定: "绑定",
  重置: "重置",
  序列号: "序列号",
  已绑定设备: "已绑定设备",
  设备类型: "设备类型",
  绑定时间: "绑定时间",
  操作: "操作",
  解绑: "解绑",
  请输入序列号: "请输入序列号",
  请输入经销商电话: "请输入经销商电话",
  请输入备注: "请输入备注",
  确定要解绑吗: "确定要解绑吗？",
  由斯姆捷科技有限公司提供技术支持: "由斯姆捷科技有限公司提供技术支持",
  日期: "日期",
  孔号: "孔号",
  试验时间: "试验时间",
  截面积: "截面积",
  表面积: "表面积", 
  位移点长度: "位移点长度",
  起始深度: "起始深度",
  打印: "打印",
  导出: "导出",
  删除: "删除",
  图形: "图形",
  详细信息: "详细信息",
  深度: "深度",
  阻力: "阻力",
  锥尖阻力: "比贯入阻力",
  确定要删除吗: "确定要删除吗?",
  深度压强: "深度/压强",
  压强: "压强",
  全屏: "全屏",
  打印图表: "打印图表",
  导出JPEG: "导出 JPEG",
  导出PDF: "导出 PDF",
  导出PNG: "导出 PNG",
  导出SVG: "导出 SVG",
  试验编号: "试验编号",
  侧壁阻力: "侧壁阻力",
  深度锥尖阻力: "深度/锥尖阻力",
  深度侧壁阻力: "深度/侧壁阻力",
  土类型: "土类型",
  工程编号: "工程编号",
  试验深度: "试验深度",
  运行系数: "运行系数",
  孔口高度: "孔口高度",
  地下水位: "地下水位",
  角度: "角度",
  抗剪强度: "抗剪强度",
  存在空数据: "存在空数据",
  请选择一条原状土和一条重塑土记录: "请选择一条原状土和一条重塑土记录",
  角度抗剪强度: "角度/抗剪强度",
  修改成功: "修改成功",
  密码不一致: "密码不一致",
  修改失败: "修改失败",
  请求失败: "请求失败!",
  请输入账号: "请输入账号",
  请输入密码: "请输入密码",
  重塑土: "重塑土",
  原状土: "原状土",
  测缝仪: "测缝仪",
  测缝仪数据查询: "测缝仪数据查询",
  构件: "构件",
  最大缝宽: "最大缝宽",
  最小缝宽: "最小缝宽",
  平均缝宽: "平均缝宽",
  加载中: "加载中",
  测点: "测点",
  缝宽: "缝宽",
  工程管理: "工程管理",
  增加: "增加",
  编辑: "编辑",
  测位管理: "测位管理",
  测点管理: "测点管理",
  无数据: "无数据",
  工程信息: "工程信息",
  工程名称: "工程名称",
  开始时间: "开始时间",
  结束时间: "结束时间",
  测位信息: "测位信息",
  测位名称: "测位名称",
  测点信息: "测点信息",
  测点名称: "测点名称",
  请输入测点名称: "请输入测点名称",
  请选择设备: "请选择设备",
  设备通道: "设备通道",
  请选择通道: "请选择通道",
  请输入工程名称: "请输入工程名称",
  请输入开始日期: "请输入开始日期",
  请输入结束日期: "请输入结束日期",
  请输入测位名称: "请输入测位名称",
  测点设备: "测点设备",
  测点通道: "测点通道",
  表层: "表层",
  中: "中",
  中上: "中上",
  中下: "中下",
  底层: "底层",
  环境: "环境",
  请选择项目: "请选择项目",
  删除成功: "删除成功",
  保存成功: "保存成功",
  请选择测位: "请选择测位",
  开始日期: "开始日期",
  结束日期: "结束日期",
  公司名称: "公司名称",
  修改公司名称: "修改公司名称",
  请选择测点: "请选择测点",
  选择日期: "选择日期",
  我的工程: "我的工程",
  测位列表: "测位列表",
  请选择工程: "请选择工程",
  请至工程管理增加测位: "请至'工程管理'增加测位",
  导出峰值数据: "导出数据",
  原始数据导出见我的设备: "原始数据导出见'我的设备'",
  该测位下无测点: "该测位下无测点",
  通道: "通道",
  最新温度: "最新温度",
  返回时间: "采集时间",
  峰值: "峰值",
  峰值时间: "峰值时间",
  谷值: "谷值",
  谷值时间: "谷值时间",
  平均值: "平均值",
  测点无数据: "测点无数据",
  项目起止: "项目起止",
  请选择日期: "请选择日期",
  温度: "温度",
  时间: "时间",
  温度曲线图: "温度曲线图",
  温差报警: "温差报警",
  上下限报警: "上下限报警",
  每天降温报警: "每天降温报警",
  每四小时降温报警: "每四小时降温报警",
  温差名称: "温差名称",
  设置差值: "设置差值",
  通道1: "通道1",
  通道1时间: "通道1时间",
  通道1温度: "通道1温度",
  通道2: "通道2",
  通道2时间: "通道2时间",
  通道2温度: "通道2温度",
  上下限名称: "上下限名称",
  通道时间: "通道时间",
  通道温度: "通道温度",
  上限值: "上限值",
  下限值: "下限值",
  时间间隔: "时间间隔",
  平均温度: "平均温度",
  降温速率报警与最大温度: "降温速率报警与最大温度",
  选择设备: "选择设备",
  每天降温: "每天降温",
  每四小时降温: "每四小时降温",
  T1最大温度: "T1最大温度",
  T2最大温度: "T2最大温度",
  T3最大温度: "T3最大温度",
  T4最大温度: "T4最大温度",
  T5最大温度: "T5最大温度",
  T6最大温度: "T6最大温度",
  T7最大温度: "T7最大温度",
  T8最大温度: "T8最大温度",
  水泵绑定: "水泵绑定",
  报警间隔: "报警间隔",
  差值: "差值",
  分钟: "分钟",
  名称: "名称",
  开关: "开关",
  请输入开关imei: "请输入开关imei",
  继电器1: "继电器1",
  继电器2: "继电器2",
  继电器3: "继电器3",
  继电器4: "继电器4",
  开关明细: "开关明细",
  关闭: "关闭",
  请输入温差名称: "请输入温差名称",
  请选择工程名称: "请选择工程名称",
  请输入报警间隔: "请输入报警间隔",
  报警间隔必须为数字值: "报警间隔必须为数字值",
  请输入差值: "请输入差值",
  请输入上下限名称: "请输入上下限名称",
  创建时间: "创建时间",
  请输入名称: "请输入名称",
  长度15个字符: "长度15个字符",
  请选择继电器: "请选择继电器",
  开关imei: "开关imei",
  继电器: "继电器",
  明细: "明细",
  请选择一条记录: "请选择一条记录",
  电池电量: "电池电量",
  无记录: "无记录",
  刷新: "刷新",
  天: "天",
  未配置工程信息: "未配置工程信息",
  工程: "工程",
  测位: "测位",
  数字格式不正确: "数字格式不正确",
  时间格式不正确: "时间格式不正确",
  至: "至",
  小时: "小时",
  无线测温仪: "无线测温仪",
  报警管理: "报警管理",
  我的设备: "我的设备",
  平均温度查询: "平均温度查询",
  报警记录查询: "报警记录查询",
  公司名称不能为空: "公司名称不能为空",
  二维码: "二维码",
  工程地址: "工程地址",
  请输入工程地址: "请输入工程地址",
  测点绑定: "测点绑定",
  传感器序列号: "传感器序列号",
  不能为空: "不能为空",
  确定要覆盖吗: "确定要覆盖吗?",
  已绑定: "已绑定",
  请选择: "请选择",
  多次: "多次",
  是: "是",
  否: "否",
  其他人绑定中: "其他人绑定中",
  降温速率报警: "降温速率报警",
  前一天: "前一天",
  后一天: "后一天",
  报警记录: "报警记录",
  编辑名称: "编辑名称",
  查看数据: "查看数据",
  解除绑定: "解除绑定",
  原始数据: "原始数据",
  计算结果: "计算结果",
  标定点: "标定点",
  电势: "电势",
  计算: "计算",
  //侯棒棒
  传感器: "传感器",
  传感器1: "传感器1",
  传感器2: "传感器2",
  传感器温度: "传感器温度",
  传感器1温度: "传感器1温度",
  传感器2温度: "传感器2温度",
  传感器时间: "传感器时间",
  传感器1时间: "传感器1时间",
  传感器2时间: "传感器2时间",
  全部标为已读: "全部标为已读",
  上限值必须为数字值: "上限值必须为数字值",
  下限值必须为数字值: "下限值必须为数字值",
  请输入上限值: "请输入上限值",
  请输入下限值: "请输入下限值",
  背光时长: "背光时长",
  采样间隔: "采样间隔",
  是否开机升级: "是否开机升级",
  请输入背光时长: "请输入背光时长",
  请输入采样间隔: "请输入采样间隔",
  保存失败: "保存失败",
  必须为数字值: "必须为数字值",
  密码为四位数字: "密码为四位数字",
  必须为整数: "必须为整数",
  对接公司名称: "对接公司名称",
  t1序列号: "t1序列号",
  t2序列号: "t2序列号",
  t3序列号: "t3序列号",
  t4序列号: "t4序列号",
  t5序列号: "t5序列号",
  t6序列号: "t6序列号",
  t7序列号: "t7序列号",
  t8序列号: "t8序列号",
};

export const vxe = {
  base: {
    pleaseInput: "请输入",
    pleaseSelect: "请选择",
  },
  loading: {
    text: "加载中...",
  },
  error: {
    groupFixed: "如果使用分组表头，固定列必须按组设置",
    groupMouseRange: '分组表头与 "{0}" 不能同时使用，这可能会出现错误',
    groupTag: '分组列头应该使用 "{0}" 而不是 "{1}"，这可能会出现错误',
    scrollErrProp: '启用虚拟滚动后不支持该参数 "{0}"',
    errConflicts: '参数 "{0}" 与 "{1}" 有冲突',
    unableInsert: "无法插入到指定位置，请检查参数是否正确",
    useErr: '安装 "{0}" 模块时发生错误，可能顺序不正确，依赖的模块需要在 Table 之前安装',
    barUnableLink: "工具栏无法关联表格",
    expandContent: '展开行的插槽应该是 "content"，请检查是否正确',
    reqModule: '缺少 "{0}" 模块',
    reqProp: '缺少必要的 "{0}" 参数，这可能会导致出现错误',
    emptyProp: '参数 "{0}" 不允许为空',
    errProp: '不支持的参数 "{0}"，可能为 "{1}"',
    colRepet: 'column.{0}="{1}" 重复了，这可能会导致某些功能无法使用',
    notFunc: '方法 "{0}" 不存在',
    errFunc: '参数 "{0}" 不是一个方法',
    notValidators: '全局校验 "{0}" 不存在',
    notFormats: '全局格式化 "{0}" 不存在',
    notCommands: '全局指令 "{0}" 不存在',
    notSlot: '插槽 "{0}" 不存在',
    noTree: '树结构不支持 "{0}"',
    notProp: '不支持的参数 "{0}"',
    checkProp: '当数据量过大时可能会导致复选框卡顿，建议设置参数 "{0}" 提升渲染速度',
    coverProp: '"{0}" 的参数 "{1}" 被覆盖，这可能会出现错误',
    delFunc: '方法 "{0}" 已废弃，请使用 "{1}"',
    delProp: '参数 "{0}" 已废弃，请使用 "{1}"',
    delEvent: '事件 "{0}" 已废弃，请使用 "{1}"',
    removeProp: '参数 "{0}" 已废弃，不建议使用，这可能会导致出现错误',
    errFormat: '全局的格式化内容应该使用 "VXETable.formats" 定义，挂载 "formatter={0}" 的方式已不建议使用',
    notType: '不支持的文件类型 "{0}"',
    notExp: "该浏览器不支持导入/导出功能",
    impFields: "导入失败，请检查字段名和数据格式是否正确",
    treeNotImp: "树表格不支持导入",
  },
  renderer: {
    search: "搜索",
    cases: {
      equal: "等于",
      unequal: "不等于",
      gt: "大于",
      ge: "大于或等于",
      lt: "小于",
      le: "小于或等于",
      begin: "开头是",
      notbegin: "开头不是",
      endin: "结尾是",
      notendin: "结尾不是",
      include: "包含",
      exclude: "不包含",
      between: "介于",
      custom: "自定义筛选",
      insensitive: "不区分大小写",
      isSensitive: "区分大小写",
    },
    combination: {
      menus: {
        clearSort: "清除排序",
        sortAsc: "升序",
        sortDesc: "降序",
        fixedColumn: "锁定列",
        fixedGroup: "锁定组",
        cancelFixed: "取消锁定",
        fixedLeft: "锁定左侧",
        fixedRight: "锁定右侧",
        clearFilter: "清除筛选",
        textOption: "文本筛选",
        numberOption: "数值筛选",
      },
      popup: {
        title: "自定义筛选的方式",
        currColumnTitle: "当前列：",
        and: "与",
        or: "或",
        describeHtml: "可用 ? 代表单个字符<br/>用 * 代表任意多个字符",
      },
      empty: "(空白)",
      notData: "无匹配项",
    },
  },
  pro: {
    area: {
      mergeErr: "无法对合并单元格进行该操作",
      multiErr: "无法对多重选择区域进行该操作",
      extendErr: "如果延伸的区域包含被合并的单元格，所有合并的单元格需大小相同",
      pasteMultiErr: "无法粘贴，需要相同大小的复制的区域和粘贴的区域才能执行此操作",
    },
    fnr: {
      title: "查找和替换",
      findLabel: "查找",
      replaceLabel: "替换",
      findTitle: "查找内容：",
      replaceTitle: "替换为：",
      tabs: {
        find: "查找",
        replace: "替换",
      },
      filter: {
        re: "正则表达式",
        whole: "全词匹配",
        sensitive: "区分大小写",
      },
      btns: {
        findNext: "查找下一个",
        findAll: "查找全部",
        replace: "替换",
        replaceAll: "替换全部",
        cancel: "取消",
      },
      header: {
        seq: "#",
        cell: "单元格",
        value: "值",
      },
      empty: "(空值)",
      reError: "无效的正则表达式",
      recordCount: "已找到 {0} 个单元格",
      notCell: "找不到匹配的单元格",
      replaceSuccess: "成功替换 {0} 个单元格",
    },
  },
  table: {
    emptyText: "暂无数据",
    allTitle: "全选/取消",
    seqTitle: "#",
    confirmFilter: "筛选",
    resetFilter: "重置",
    allFilter: "全部",
    sortSingle: "点击排序",
    sortAsc: "升序：最低到最高",
    sortDesc: "降序：最高到最低",
    filter: "对所选的列启用筛选",
    impSuccess: "成功导入 {0} 条记录",
    expLoading: "正在导出中",
    expSuccess: "导出成功",
    expFilename: "导出_{0}",
    expOriginFilename: "导出_源_{0}",
    customTitle: "列设置",
    customAll: "全部",
    customConfirm: "确认",
    customRestore: "重置",
    maxFixedCol: "最大固定列的数量不能超过 {0} 个",
  },
  grid: {
    selectOneRecord: "请至少选择一条记录！",
    deleteSelectRecord: "您确定要删除所选记录吗？",
    removeSelectRecord: "您确定要移除所选记录吗？",
    dataUnchanged: "数据未改动！",
    delSuccess: "成功删除所选记录！",
    saveSuccess: "保存成功！",
    operError: "发生错误，操作失败！",
  },
  select: {
    search: "搜索",
    loadingText: "加载中",
    emptyText: "暂无数据",
  },
  pager: {
    goto: "前往",
    pagesize: "{0}条/页",
    total: "共 {0} 条记录",
    pageClassifier: "页",
    homePage: "首页",
    homePageTitle: "首页",
    prevPage: "上一页",
    prevPageTitle: "上一页",
    nextPage: "下一页",
    nextPageTitle: "下一页",
    prevJump: "向上跳页",
    prevJumpTitle: "向上跳页",
    nextJump: "向下跳页",
    nextJumpTitle: "向下跳页",
    endPage: "末页",
    endPageTitle: "末页",
  },
  alert: {
    title: "消息提示",
  },
  button: {
    confirm: "确认",
    cancel: "取消",
  },
  import: {
    modes: {
      covering: "覆盖",
      insert: "新增",
    },
    impTitle: "导入数据",
    impFile: "文件名",
    impSelect: "选择文件",
    impType: "文件类型",
    impOpts: "参数设置",
    impConfirm: "导入",
    impCancel: "取消",
  },
  export: {
    types: {
      csv: "CSV (逗号分隔)(*.csv)",
      html: "网页(*.html)",
      xml: "XML 数据(*.xml)",
      txt: "文本文件(制表符分隔)(*.txt)",
      xls: "Excel 97-2003 工作簿(*.xls)",
      xlsx: "Excel 工作簿(*.xlsx)",
      pdf: "PDF (*.pdf)",
    },
    modes: {
      current: "当前数据（当前页的数据）",
      selected: "选中数据（当前页选中的数据）",
      all: "全量数据（包括所有分页的数据）",
    },
    printTitle: "打印数据",
    expTitle: "导出数据",
    expName: "文件名",
    expNamePlaceholder: "请输入文件名",
    expSheetName: "标题",
    expSheetNamePlaceholder: "请输入标题",
    expType: "保存类型",
    expMode: "选择数据",
    expCurrentColumn: "全部字段",
    expColumn: "选择字段",
    expOpts: "参数设置",
    expOptHeader: "表头",
    expHeaderTitle: "是否需要表头",
    expOptFooter: "表尾",
    expFooterTitle: "是否需要表尾",
    expOptColgroup: "分组表头",
    expColgroupTitle: "如果存在，则支持带有分组结构的表头",
    expOptMerge: "合并",
    expMergeTitle: "如果存在，则支持带有合并结构的单元格",
    expOptAllExpand: "展开层级",
    expAllExpandTitle: "如果存在，则支持将带有层级结构的数据全部展开",
    expOptUseStyle: "样式",
    expUseStyleTitle: "如果存在，则支持带样式的单元格",
    expOptOriginal: "源数据",
    expOriginalTitle: "如果为源数据，则支持导入到表格中",
    expPrint: "打印",
    expConfirm: "导出",
    expCancel: "取消",
  },
  modal: {
    zoomIn: "最大化",
    zoomOut: "还原",
    close: "关闭",
  },
  form: {
    folding: "收起",
    unfolding: "展开",
  },
  toolbar: {
    import: "导入",
    export: "导出",
    print: "打印",
    refresh: "刷新",
    zoomIn: "全屏",
    zoomOut: "还原",
    custom: "列设置",
    customAll: "全部",
    customConfirm: "确认",
    customRestore: "重置",
    fixedLeft: "固定在左侧",
    fixedRight: "固定在右侧",
    cancelfixed: "取消固定",
  },
  input: {
    date: {
      m1: "01 月",
      m2: "02 月",
      m3: "03 月",
      m4: "04 月",
      m5: "05 月",
      m6: "06 月",
      m7: "07 月",
      m8: "08 月",
      m9: "09 月",
      m10: "10 月",
      m11: "11 月",
      m12: "12 月",
      quarterLabel: "{0} 年",
      monthLabel: "{0} 年",
      dayLabel: "{0} 年 {1}",
      labelFormat: {
        date: "yyyy-MM-dd",
        time: "HH:mm:ss",
        datetime: "yyyy-MM-dd HH:mm:ss",
        week: "yyyy 年第 WW 周",
        month: "yyyy-MM",
        quarter: "yyyy 年第 q 季度",
        year: "yyyy",
      },
      weeks: {
        w: "周",
        w0: "周日",
        w1: "周一",
        w2: "周二",
        w3: "周三",
        w4: "周四",
        w5: "周五",
        w6: "周六",
      },
      months: {
        m0: "一月",
        m1: "二月",
        m2: "三月",
        m3: "四月",
        m4: "五月",
        m5: "六月",
        m6: "七月",
        m7: "八月",
        m8: "九月",
        m9: "十月",
        m10: "十一月",
        m11: "十二月",
      },
      quarters: {
        q1: "第一季度",
        q2: "第二季度",
        q3: "第三季度",
        q4: "第四季度",
      },
    },
  },
};
