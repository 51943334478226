export const lang = {
  语言: "English",
  登录: "登錄",
  微信登录: "微信登錄",
  账户登录: "賬戶登錄",
  请使用微信扫描二维码登录: "請使用微信掃描二維碼登錄",
  公司版权所有北京路晨伟业仪器设备有限公司: "公司版權所有：北京路晨偉業儀器設備有限公司",
  京ICP备18062262号1: "京ICP備18062262号-1",
  设备数据: "設備數據",
  首页: "首頁",
  手动单通道: "手動單通道",
  自动单通道: "自動單通道",
  手动双通道: "手動雙通道",
  自动双通道: "自動雙通道",
  单通道带剪切力: "單通道帶剪切力",
  静力触探: "靜力觸探",
  修改密码: "修改密碼",
  退出: "退出",
  旧密码: "舊密碼",
  新密码: "新密碼",
  确认新密码: "確認新密碼",
  保存: "保存",
  取消: "取消",
  密码至少为6位: "密碼至少為6位",
  密码错误: "密碼錯誤",
  微信用户: "微信用戶",
  确定要退出吗: "確定要退出嗎？",
  新增绑定设备: "新增綁定設備",
  设备序列号: "設備序列號",
  经销商电话: "經銷商電話",
  备注: "備註",
  绑定: "綁定",
  重置: "重置",
  序列号: "序列號",
  已绑定设备: "已綁定設備",
  设备类型: "設備類型",
  绑定时间: "綁定時間",
  操作: "操作",
  解绑: "解綁",
  请输入序列号: "請輸入序列號",
  请输入经销商电话: "請輸入經銷商電話",
  请输入备注: "請輸入備註",
  确定要解绑吗: "確定要解綁嗎？",
  由斯姆捷科技有限公司提供技术支持: "收斯姆捷科技有限公司提供技術支持",
  日期: "日期",
  孔号: "孔號",
  试验时间: "試驗時間",
  截面积: "截面積",
  表面积: "表面積",
  位移点长度: "位移點長度",
  起始深度: "起始深度",
  打印: "打印",
  导出: "導出",
  删除: "刪除",
  图形: "圖形",
  详细信息: "詳細信息",
  深度: "深度",
  阻力: "阻力",
  锥尖阻力: "錐尖阻力",
  确定要删除吗: "確定要刪除嗎?",
  深度压强: "深度/壓強",
  压强: "壓強",
  全屏: "全屏",
  打印图表: "打印圖表",
  导出JPEG: "導出 JPEG",
  导出PDF: "導出 PDF",
  导出PNG: "導出 PNG",
  导出SVG: "導出 SVG",
  试验编号: "試驗編號",
  侧壁阻力: "側壁阻力",
  深度锥尖阻力: "深度/錐尖阻力",
  深度侧壁阻力: "深度/側壁阻力",
  土类型: "土類型",
  工程编号: "工程編號",
  试验深度: "試驗深度",
  运行系数: "運行係數",
  孔口高度: "孔口高度",
  地下水位: "地下水位",
  角度: "角度",
  抗剪强度: "抗剪強度",
  存在空数据: "存在空數據",
  请选择一条原状土和一条重塑土记录: "請選擇一條原狀土和一條重塑土記錄",
  角度抗剪强度: "角度/抗剪強度",
  修改成功: "修改成功",
  密码不一致: "密碼不一致",
  修改失败: "修改失敗",
  请求失败: "請求失敗!",
  请输入账号: "請輸入賬號",
  请输入密码: "請輸入密碼",
  重塑土: "重塑土",
  原状土: "原狀土",
  测缝仪: "測縫儀",
  测缝仪数据查询: "測縫儀數據查詢",
  构件: "構件",
  最大缝宽: "最大縫寬",
  最小缝宽: "最小縫寬",
  平均缝宽: "平均縫寬",
  加载中: "加載中",
  测点: "測點",
  缝宽: "縫寬",
  工程管理: "工程管理",
  增加: "增加",
  编辑: "編輯",
  测位管理: "測位管理",
  测点管理: "測點管理",
  无数据: "無數據",
  工程信息: "工程信息",
  工程名称: "工程名稱",
  开始时间: "開始時間",
  结束时间: "結束時間",
  测位信息: "測位信息",
  测位名称: "測位名稱",
  测点信息: "測點信息",
  测点名称: "測點名稱",
  请输入测点名称: "請輸入測點名稱",
  请选择设备: "請選擇設備",
  设备通道: "設備通道",
  请选择通道: "請選擇通道",
  请输入工程名称: "請輸入工程名稱",
  请输入开始日期: "請輸入開始日期",
  请输入结束日期: "請輸入結束日期",
  请输入测位名称: "請輸入測位名稱",
  测点设备: "測點設備",
  测点通道: "測點通道",
  表层: "表層",
  中: "中",
  中上: "中上",
  中下: "中下",
  底层: "底層",
  环境: "環境",
  请选择项目: "請選擇項目",
  删除成功: "刪除成功",
  保存成功: "積存成功",
  请选择测位: "請選擇測位",
  开始日期: "開始日期",
  结束日期: "結束日期",
  公司名称: "公司名稱",
  修改公司名称: "修改公司名稱",
  请选择测点: "請選擇測點",
  选择日期: "選擇日期",
  我的工程: "我的工程",
  测位列表: "測位列表",
  请选择工程: "請選擇工程",
  请至工程管理增加测位: "請至'工程管理'增加測位",
  导出峰值数据: "導出數據",
  原始数据导出见我的设备: "原始數據導出見'我的設備'",
  该测位下无测点: "該測位下無測點",
  通道: "通道",
  最新温度: "最新溫度",
  返回时间: "採集時間",
  峰值: "峰值",
  峰值时间: "峰值時間",
  谷值: "谷值",
  谷值时间: "谷值時間",
  平均值: "平均值",
  测点无数据: "測點無數據",
  项目起止: "項目起止",
  请选择日期: "請選擇日期",
  温度: "溫度",
  时间: "時間",
  温度曲线图: "溫度曲線圖",
  温差报警: "溫差報警",
  上下限报警: "上下限報警",
  每天降温报警: "每天降溫報警",
  每四小时降温报警: "每四小時降溫報警",
  温差名称: "溫差名稱",
  设置差值: "設置差值",
  通道1: "通道1",
  通道1时间: "通道1時間",
  通道1温度: "通道1溫度",
  通道2: "通道2",
  通道2时间: "通道2時間",
  通道2温度: "通道2溫度",
  上下限名称: "上下限名稱",
  通道时间: "通道時間",
  通道温度: "通道溫度",
  上限值: "上限值",
  下限值: "下限值",
  时间间隔: "時間間隔",
  平均温度: "平均溫度",
  降温速率报警与最大温度: "降溫速率報警與最大溫度",
  选择设备: "選擇設備",
  每天降温: "每天降溫",
  每四小时降温: "每四小時降溫",
  T1最大温度: "T1最大溫度",
  T2最大温度: "T2最大溫度",
  T3最大温度: "T3最大溫度",
  T4最大温度: "T4最大溫度",
  T5最大温度: "T5最大溫度",
  T6最大温度: "T6最大溫度",
  T7最大温度: "T7最大溫度",
  T8最大温度: "T8最大溫度",
  水泵绑定: "水泵綁定",
  报警间隔: "報警間隔",
  差值: "差值",
  分钟: "分鐘",
  名称: "名稱",
  开关: "開關",
  请输入开关imei: "請輸入開關imei",
  继电器1: "繼電器1",
  继电器2: "繼電器2",
  继电器3: "繼電器3",
  继电器4: "繼電器4",
  开关明细: "開關明細",
  关闭: "關閉",
  请输入温差名称: "請輸入溫差名稱",
  请选择工程名称: "請選擇工程名稱",
  请输入报警间隔: "請輸入報警間隔",
  报警间隔必须为数字值: "報警間隔必須為數字值",
  请输入差值: "請輸入差值",
  请输入上下限名称: "請輸入上下限名稱",
  创建时间: "創建時間",
  请输入名称: "請輸入名稱",
  长度15个字符: "長度15個字符",
  请选择继电器: "請選擇繼電器",
  开关imei: "開關imei",
  继电器: "繼電器",
  明细: "明細",
  请选择一条记录: "請選擇一條記錄",
  电池电量: "電池電量",
  无记录: "無記錄",
  刷新: "刷新",
  天: "天",
  未配置工程信息: "未配置工程信息",
  工程: "工程",
  测位: "測位",
  数字格式不正确: "數字格式不正確",
  时间格式不正确: "時間格式不正確",
  至: "至",
  小时: "小時",
  无线测温仪: "無線測溫儀",
  报警管理: "報警管理",
  我的设备: "我的設備",
  平均温度查询: "平均溫度查詢",
  报警记录查询: "報警記錄查詢",
  公司名称不能为空: "公司名稱不能為空",
  二维码: "二維碼",
  工程地址: "工程地址",
  请输入工程地址: "請輸入工程地址",
  测点绑定: "測點綁定",
  传感器序列号: "傳感器序列號",
  不能为空: "不能為空",
  确定要覆盖吗: "確定要覆蓋嗎?",
  已绑定: "已綁定",
  请选择: "請選擇",
  多次: "多次",
  是: "是",
  否: "否",
  其他人绑定中: "其他人綁定中",
  降温速率报警: "降溫速率報警",
  前一天: "前一天",
  后一天: "后一天",
  报警记录: "報警記錄",
  编辑名称: "編輯名稱",
  查看数据: "查看數據",
  解除绑定: "解除綁定",
  原始数据: "原始數據",
  计算结果: "計算結果",
  标定点: "標定點",
  电势: "電勢",
  计算: "計算",
  //侯棒棒
  传感器: "感測器",
  传感器1: "感測器1",
  传感器2: "感測器2",
  传感器温度: "感測器溫度",
  传感器1温度: "感測器1溫度",
  传感器2温度: "感測器2溫度",
  传感器时间: "感測器時間",
  传感器1时间: "感測器1時間",
  传感器2时间: "感測器2時間",
  全部标为已读: "全部標為已讀",
  上限值必须为数字值: "上限值必須為數位值",
  下限值必须为数字值: "下限值必須為數位值",
  请输入上限值: "請輸入上限值",
  请输入下限值: "請輸入下限值",
  背光时长: "背光時長",
  采样间隔: "採樣間隔",
  是否开机升级: "是否開機陞級",
  请输入背光时长: "請輸入背光時長",
  请输入采样间隔: "請輸入採樣間隔",
  保存失败: "保存失敗",
  必须为数字值: "必須為數位值",
  密码为四位数字: "密碼為四比特數位",
  必须为整数: "必須為整數",
  对接公司名称: "對接公司名稱",
  t1序列号: "t1序號",
  t2序列号: "t2序號",
  t3序列号: "t3序號",
  t4序列号: "t4序號",
  t5序列号: "t5序號",
  t6序列号: "t6序號",
  t7序列号: "t7序號",
  t8序列号: "t8序號",
};

export const vxe = {
  base: {
    pleaseInput: "请输入",
    pleaseSelect: "请选择",
  },
  loading: {
    text: "加載中...",
  },
  error: {
    groupFixed: "如果使用分組表頭，固定列必須按組設定",
    groupMouseRange: '分组表頭與 "{0}" 不能同時使用，這可能會出現錯誤',
    groupTag: '分組列頭應該使用 "{0}" 而不是 "{1}"，這可能會出現錯誤',
    scrollErrProp: '啟用虛擬滾動後不支持該參數 "{0}"',
    errConflicts: '參數 "{0}" 與 "{1}" 有衝突',
    unableInsert: "無法插入到指定位置，請檢查參數是否正確",
    useErr: '安裝 "{0}" 模組時發生錯誤，可能順序不正確，依賴的模組需要在Table之前安裝',
    barUnableLink: "工具欄無法關聯表格",
    expandContent: "展開行的插槽應該是 “content”，請檢查是否正確",
    reqModule: '缺少 "{0}" 模組',
    reqProp: '缺少必要的 "{0}" 參數，可能會導致出現錯誤',
    emptyProp: '參數 "{0}" 不允許為空',
    errProp: '不支持的參數 "{0}"，可能為 "{1}"',
    colRepet: 'column.{0}="{1}" 重複了，這可能會導致某些功能無法使用',
    notFunc: '方法 "{0}" 不存在',
    errFunc: '參數 "{0}" 不是一個方法',
    notValidators: '全局校验 "{0}" 不存在',
    notFormats: '全局格式化 "{0}" 不存在',
    notCommands: '全局指令 "{0}" 不存在',
    notSlot: '插槽 "{0}" 不存在',
    noTree: "樹狀結構不支援 {0}",
    notProp: '不支持的參數 "{0}"',
    checkProp: '當數據量過大時可能會導致複選框卡頓，建議設置參數 "{0}" 提升渲染速度',
    coverProp: '"{0}" 的參數 "{1}" 被覆蓋，這可能會出現錯誤',
    delFunc: '方法 "{0}" 已停用，請使用 "{1}"',
    delProp: '參數 "{0}" 已停用，請使用 "{1}"',
    delEvent: '事件 "{0}" 已停用，請使用 "{1}"',
    removeProp: '參數 "{0}" 已停用，不建議使用，這可能會導致出現錯誤',
    errFormat: '全域的格式化內容應該使用 "VXETable.formats" 定義，掛載 "formatter={0}" 的管道已不建議使用',
    notType: '不支持的檔案類型 "{0}"',
    notExp: "該瀏覽器不支持導入/匯出功能",
    impFields: "導入失敗，請檢查欄位名和數據格式是否正確",
    treeNotImp: "樹狀表格不支持導入",
  },
  renderer: {
    search: "蒐索",
    cases: {
      equal: "等於",
      unequal: "不等於",
      gt: "大於",
      ge: "大於或等於",
      lt: "小於",
      le: "小於或等於",
      begin: "開頭是",
      notbegin: "開頭不是",
      endin: "結尾是",
      notendin: "結尾不是",
      include: "包含",
      exclude: "不包含",
      between: "介於",
      custom: "自定義篩選",
      insensitive: "不區分大小寫",
      isSensitive: "區分大小寫",
    },
    combination: {
      menus: {
        clearSort: "清除排序",
        sortAsc: "昇冪",
        sortDesc: "降序",
        fixedColumn: "鎖定列",
        fixedGroup: "鎖定組",
        cancelFixed: "取消鎖定",
        fixedLeft: "鎖定左側",
        fixedRight: "鎖定右側",
        clearFilter: "清除篩選",
        textOption: "文字篩選",
        numberOption: "數值篩選",
      },
      popup: {
        title: "自定義篩選的管道",
        currColumnTitle: "當前列：",
        and: "與",
        or: "或",
        describeHtml: "用 ? 代表單個字元<br/>用 * 代表任意多個字元",
      },
      empty: "(空白)",
      notData: "無匹配項",
    },
  },
  pro: {
    area: {
      mergeErr: "無法對合併儲存格進行該操作",
      multiErr: "無法對多重選擇區域進行該操作",
      extendErr: "如果延伸的區域包含被合併的儲存格，所有合併的儲存格需大小相同",
      pasteMultiErr: "无法粘贴，需要相同大小的复制的区域和粘贴的区域才能执行此操作",
    },
    fnr: {
      title: "查找和替換",
      findLabel: "查找",
      replaceLabel: "替換",
      findTitle: "查找內容：",
      replaceTitle: "替換為：",
      tabs: {
        find: "查找",
        replace: "替換",
      },
      filter: {
        re: "規則運算式",
        whole: "全詞匹配",
        sensitive: "區分大小寫",
      },
      btns: {
        findNext: "查找下一個",
        findAll: "查找全部",
        replace: "替换",
        replaceAll: "替换全部",
        cancel: "取消",
      },
      header: {
        seq: "#",
        cell: "儲存格",
        value: "值",
      },
      empty: "(空值)",
      reError: "無效的規則運算式",
      recordCount: "已找到 {0} 個儲存格",
      notCell: "找不到匹配的儲存格",
      replaceSuccess: "成功替換 {0} 個儲存格",
    },
  },
  table: {
    emptyText: "暫無資料",
    allTitle: "全選/取消",
    seqTitle: "#",
    confirmFilter: "篩選",
    resetFilter: "重置",
    allFilter: "全部",
    sortSingle: "點擊排序",
    sortAsc: "按低到高排序",
    sortDesc: "按高到低排序",
    filter: "對所選的列啟用篩選",
    impSuccess: "成功導入 {0} 條記錄",
    expLoading: "正在匯出中",
    expSuccess: "匯出成功",
    expOriginFilename: "匯出_{0}",
    expSrcFilename: "匯出_從_{0}",
    customTitle: "列設定",
    customAll: "全部",
    customConfirm: "確認",
    customRestore: "重置",
    maxFixedCol: "最大固定列的數量不能超過 {0} 個",
  },
  grid: {
    selectOneRecord: "請至少選擇一條記錄！",
    deleteSelectRecord: "您確定要刪除所選記錄嗎？",
    removeSelectRecord: "您確定要移除所選記錄嗎？",
    dataUnchanged: "資料未更改！ ",
    delSuccess: "成功删除所選記錄！",
    saveSuccess: "保存成功！",
    operError: "發生錯誤，操作失敗！",
  },
  select: {
    search: "蒐索",
    loadingText: "加載中",
    emptyText: "暫無資料",
  },
  pager: {
    goto: "前往",
    pagesize: "{0}項/頁",
    total: "共 {0} 項記錄",
    pageClassifier: "頁",
    homePage: "首頁",
    homePageTitle: "首頁",
    prevPage: "上一頁",
    prevPageTitle: "上一頁",
    nextPage: "下一頁",
    nextPageTitle: "下一頁",
    prevJump: "向上跳頁",
    prevJumpTitle: "向上跳頁",
    nextJump: "向下跳頁",
    nextJumpTitle: "向下跳頁",
    endPage: "末頁",
    endPageTitle: "末頁",
  },
  alert: {
    title: "訊息提示",
  },
  button: {
    confirm: "確認",
    cancel: "取消",
  },
  import: {
    modes: {
      covering: "覆盖",
      insert: "新增",
    },
    impTitle: "導入數據",
    impFile: "檔名",
    impSelect: "選擇檔案",
    impType: "檔案類型",
    impOpts: "參數設置",
    impConfirm: "導入",
    impCancel: "取消",
  },
  export: {
    types: {
      csv: "CSV (逗号分隔)(*.csv)",
      html: "網頁(*.html)",
      xml: "XML 文件(*.xml)",
      txt: "文本文件(制表符分隔)(*.txt)",
      xls: "Excel 97-2003 工作簿(*.xls)",
      xlsx: "Excel 工作簿(*.xlsx)",
      pdf: "PDF (*.pdf)",
    },
    modes: {
      current: "當前數據（當前頁的數據）",
      selected: "選中數據（當前頁選中的數據）",
      all: "全量數據（包括所有分頁的數據）",
    },
    printTitle: "列印數據",
    expTitle: "匯出數據",
    expName: "檔名",
    expNamePlaceholder: "請輸入檔名",
    expSheetName: "標題",
    expSheetNamePlaceholder: "請輸入標題",
    expType: "保存類型",
    expMode: "選擇數據",
    expCurrentColumn: "全部欄位",
    expColumn: "選擇欄位",
    expOpts: "參數設置",
    expOptHeader: "表頭",
    expHeaderTitle: "是否需要表頭",
    expOptFooter: "表尾",
    expFooterTitle: "是否需要表尾",
    expOptColgroup: "分组表头",
    expColgroupTitle: "如果存在，則支持帶有分組結構的表頭",
    expOptMerge: "合併",
    expMergeTitle: "如果存在，則支持帶有合併結構的儲存格",
    expOptAllExpand: "展開層級",
    expAllExpandTitle: "如果存在，則支持將帶有樹結構的數據全部展開",
    expOptUseStyle: "樣式",
    expUseStyleTitle: "如果存在，則支持帶樣式的儲存格",
    expOptOriginal: "源數據",
    expOriginalTitle: "如果為源數據，則支持導入到表格中",
    expPrint: "列印",
    expConfirm: "匯出",
    expCancel: "取消",
  },
  modal: {
    zoomIn: "最大化",
    zoomOut: "還原",
    close: "關閉",
  },
  form: {
    folding: "收起",
    unfolding: "展開",
  },
  toolbar: {
    import: "導入",
    export: "匯出",
    print: "列印",
    refresh: "刷新",
    zoomIn: "全螢幕",
    zoomOut: "還原",
    custom: "列設定",
    customAll: "全部",
    customConfirm: "確認",
    customRestore: "重置",
    fixedLeft: "固定在左側",
    fixedRight: "固定在右側",
    cancelfixed: "取消固定",
  },
  input: {
    date: {
      m1: "01 月",
      m2: "02 月",
      m3: "03 月",
      m4: "04 月",
      m5: "05 月",
      m6: "06 月",
      m7: "07 月",
      m8: "08 月",
      m9: "09 月",
      m10: "10 月",
      m11: "11 月",
      m12: "12 月",
      quarterLabel: "{0} 年",
      monthLabel: "{0} 年",
      dayLabel: "{0} 年 {1}",
      labelFormat: {
        date: "yyyy-MM-dd",
        time: "HH:mm:ss",
        datetime: "yyyy-MM-dd HH:mm:ss",
        week: "yyyy 年第 WW 周",
        month: "yyyy-MM",
        quarter: "yyyy 年第 q 季度",
        year: "yyyy",
      },
      weeks: {
        w: "周",
        w0: "周日",
        w1: "周一",
        w2: "周二",
        w3: "周三",
        w4: "周四",
        w5: "周五",
        w6: "周六",
      },
      months: {
        m0: "一月",
        m1: "二月",
        m2: "三月",
        m3: "四月",
        m4: "五月",
        m5: "六月",
        m6: "七月",
        m7: "八月",
        m8: "九月",
        m9: "十月",
        m10: "十一月",
        m11: "十二月",
      },
      quarters: {
        q1: "第一季度",
        q2: "第二季度",
        q3: "第三季度",
        q4: "第四季度",
      },
    },
  },
};
